@import "email-custom";

.button--social__icon {
  display: flex;
  align-items: center;
}

.button--social__text__custom{
  display: block;
  padding: .85em 1rem;
  margin-left: 6rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.initiative-types-select {
  white-space: normal !important;
  word-break: break-all !important;
  overflow-wrap: break-word !important;

  padding-bottom: 0;

  option {
    position: relative;
    display: block;
    margin: 0 -15px 5px  0;
    padding: 5px 10px;
    font-size: 90%;
    line-height: 17px;
    color: $secondary;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    cursor: pointer;
    white-space: normal !important;
    word-break: break-all !important;
    overflow-wrap: break-word !important;
    text-overflow: clip;
    overflow-x: hidden;

    &:hover {
      background: #e8e8e8;
    }
  }

  &:focus {
    border-color: #238FF7;
    option:checked {
      background: #238FF7;
      color: white;
    }
  }
}

.highlighted_proposals .card--proposal {
  width: 100%;
}

.main-footer__badge {
  text-align: center;
}

@media print, screen and (min-width: 64em) {
  .main-footer__badge {
    position: relative;
    text-align: left;
  }
}

.topbar div {
  align-items: center;
}

.mini-footer .row {
  display: flex;
  align-items: center;
  justify-content: center;

  .decidim-logo {
    display: flex;
    flex-grow: 0;
    width: auto;
    margin: 0 .5rem;
  }

  img {
    height: 1rem;
    margin-top: -0.25rem;
  }

  .mf-text {
    font-size: 80%;
    line-height: 1rem;

    a {
      font-weight: 600;

      &:hover {
        color: white;
      }
    }
  }
}

a[title="Find out more about cookies"]{
  color: #000000;
  text-decoration: underline;
}
